//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const/index';
import navigationUser from '~/config/navigationUser';

export default {
  props: {
    username: { type: [String, Promise], default: '' },
    id: { type: Number, default: 0 },
    onlineStatus: { type: Number, default: null },
    noLink: { type: Boolean, default: false },
    copy: { type: Boolean, default: false },
  },

  data() {
    return {
      name: '',
    };
  },

  computed: {
    displayName() {
      return typeof this.$props.username === 'object' ? this.name : this.$props.username;
    },
    userLink() {
      return { name: this.userRouteName, params: { id: this.id } };
    },
    color() {
      const { onlineStatus } = this;
      const { OFFLINE, ONLINE, AWAY } = constant.users.ONLINE_STATUS;
      if (onlineStatus === ONLINE) { return 'green'; }
      if (onlineStatus === AWAY) { return 'yellow'; }
      if (onlineStatus === OFFLINE) { return 'red'; }
      return '';
    },

    userRouteName() {
      return navigationUser[0]?.routeName || 'user-id';
    },
  },

  watch: {
    username: {
      handler: 'updateName',
      immediate: true,
    },
  },

  mounted() {
    this.updateName();
  },

  methods: {
    ...mapActions('common', ['setSuccessNotification']),
    async copyMessage(message) {
      await navigator.clipboard.writeText(message);
      this.setSuccessNotification('Сopied to clipboard');
    },

    updateName() {
      if (this.$props.username === null) {
        this.name = this.$t('No username');
      } else if (typeof this.$props.username === 'object') {
        this.$props.username.then((response) => {
          this.name = response.userName;
        });
      } else {
        this.name = this.$props.username;
      }
    },
  },
};
